<!--
  PACKAGE_NAME : src/pages/ewm/hr/work/schedule/popup
  FILE_NAME : modal-modify-attendance.vue
  AUTHOR : devyoon91
  DATE : 2024-06-22
  DESCRIPTION : 출결정보변경 팝업
-->
<template>
  <div>
    <DxPopup
      :ref="modifyAttendancePopup.ref"
      :title="modifyAttendancePopup.title"
      :visible="isOpen"
      :show-title="true"
      :min-width="modifyAttendancePopup.minWidth"
      :width="modifyAttendancePopup.width"
      :min-height="modifyAttendancePopup.minHeight"
      :height="modifyAttendancePopup.height"
      :resize-enabled="false"
      :drag-enabled="true"
      :show-close-button="true"
      :close-on-outside-click="false"
      @hiding="closeModal"
    >
      <template #content>
        <div>
          <DxValidationGroup ref="attendanceModifyValidate">
            <table class="table_form line-bin">
              <colgroup>
                <col style="width: 25%"/>
                <col style="width: 30%"/>
                <col style="width: 15%"/>
                <col style="width: 30%"/>
              </colgroup>
              <tbody>
              <tr>
                <th scope="row">
                  <label for="label5">상담원</label>
                </th>
                <td colspan="1" class="clearfix">
                  <div v-if="agentData.agtNm">{{ agentData.agtNm }}({{ agentData.agtid }})</div>
                </td>
                <th scope="row">
                  <label for="label5">소속</label>
                </th>
                <td colspan="1" class="clearfix">
                  <div v-if="agentData.deptNmPath">
                    {{ agentData.deptNmPath }}
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <label for="label5">근무일</label>
                </th>
                <td colspan="1" class="clearfix">
                  <div v-if="agentData.workDt">
                    {{ agentData.workDt }}
                  </div>
                </td>
                <th scope="row">
                  <label for="label5">근무 유형</label>
                </th>
                <td colspan="1" class="clearfix">
                  <div v-if="agentData.workBasicNm">
                    {{ agentData.workBasicNm }}/ {{ agentData.workBasicStartTime }} ~
                    {{ agentData.workBasicEndTime }}
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <label for="label5">출결 정보</label>
                </th>
                <td colspan="1" class="clearfix">
                  <div v-if="attendanceState">
                    {{ getAttendanceState() }}
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <label for="label5">출결 변경<span class="icon_require">필수항목</span></label>
                </th>
                <td colspan="1" class="clearfix">
                  <DxSelectBox
                    placeholder="선택"
                    :items="getAttendanceStateList()"
                    display-expr="codeNm"
                    value-expr="id"
                    v-model="changeAttendanceState"
                    styling-mode="outlined"
                    class="mar_ri10"
                    :width="110"
                    :height="34"
                  >
                    <DxValidator>
                      <DxRequiredRule message="출결 변경값은 필수 입니다."/>
                    </DxValidator>
                  </DxSelectBox>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <label for="label5">출결 시간 <span class="icon_require">필수항목</span></label>
                </th>
                <td colspan="3" class="clearfix">
                  <DxDateBox
                    v-model="startDateBox.value"
                    :type="startDateBox.type"
                    :picker-type="startDateBox.pickerType"
                    :interval="startDateBox.interval"
                    :display-format="startDateBox.displayFormat"
                    :dateOutOfRangeMessage="startDateBox.dateOutOfRangeMessage"
                    :styling-mode="startDateBox.stylingMode"
                    :width="startDateBox.width"
                    :aria-placeholder="startDateBox.placeholder"
                  >
                    <DxValidator>
                      <DxRequiredRule
                        :message="$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE', { defaultValue: '필수값 입니다.'})"/>
                      <DxRangeRule :max="endDateBox.value"
                                   :message="$_msgContents('COMMON.MESSAGE.SEARCH_TIME_MAX_RANGE_ERROR', { defaultValue: '시작시간은 종료시간보다 작거나 같아야 합니다.'})"/>
                    </DxValidator>
                  </DxDateBox>
                  <span class="pl-1 pr-1">~</span>
                  <DxDateBox
                    v-model="endDateBox.value"
                    :type="endDateBox.type"
                    :picker-type="endDateBox.pickerType"
                    :interval="endDateBox.interval"
                    :display-format="endDateBox.displayFormat"
                    :dateOutOfRangeMessage="endDateBox.dateOutOfRangeMessage"
                    :styling-mode="endDateBox.stylingMode"
                    :width="endDateBox.width"
                    :aria-placeholder="endDateBox.placeholder"
                  >
                    <DxValidator>
                      <DxRequiredRule
                        :message="$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE', { defaultValue: '필수값 입니다.'})"/>
                      <DxRangeRule :min="startDateBox.value"
                                   :message="$_msgContents('COMMON.MESSAGE.SEARCH_TIME_MIN_RANGE_ERROR', { defaultValue: '종료시간은 시작시간보다 크거나 같아야 합니다.'})"/>
                    </DxValidator>
                  </DxDateBox>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <label for="label5">변경 사유 <span class="icon_require">필수항목</span></label>
                </th>
                <td colspan="3" class="clearfix">
                  <DxTextArea
                    v-model="attendanceReason.contents"
                    :styling-mode="attendanceReason.stylingMode"
                    :width="attendanceReason.width"
                    :height="attendanceReason.height"
                    :placeholder="attendanceReason.placeholder"
                  >
                    <DxValidator>
                      <DxRequiredRule
                        :message="$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE', { defaultValue: '필수값 입니다.'})"/>
                    </DxValidator>
                  </DxTextArea>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="page-sub-box mt-3">
              <div class="bottom-btn-wrap text-center">
                <DxButton
                  text="저장"
                  :width="120"
                  :height="40"
                  class="default filled txt_S medium"
                  :use-submit-behavior="true"
                  @click="saveModal"
                />
                <DxButton text="취 소" :width="120" :height="40" class="white filled txt_S medium" @click="closeModal"/>
              </div>
            </div>
          </DxValidationGroup>
        </div>
      </template>
    </DxPopup>
  </div>
</template>

<script>
import {DxTextArea} from "devextreme-vue/text-area";
import DxPopup from 'devextreme-vue/popup';
import {DxRangeRule, DxRequiredRule, DxValidator} from 'devextreme-vue/validator';
import {DxSelectBox} from 'devextreme-vue/select-box';
import {DxDateBox} from 'devextreme-vue/date-box';
import {DxButton} from "devextreme-vue/button";
import {DxValidationGroup} from "devextreme-vue/validation-group";

export default {
  components: {
    DxValidationGroup,
    DxButton,
    DxTextArea,
    DxDateBox,
    DxRangeRule,
    DxSelectBox,
    DxValidator,
    DxRequiredRule,
    DxPopup
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    agentData: {
      // 상담원 정보
      type: Object,
      default: () => {
      },
    },
    attendanceState: {
      // 출결상태
      type: String,
      default: '',
    },
  },
  data() {
    return {
      modifyAttendancePopup: {
        ref: 'modifyAttendancePopup',
        title: '출결정보 변경',
        minWidth: 700,
        width: 700,
        minHeight: 550,
        height: 550,
      },
      changeAttendanceState: '', // 변경할 출결상태
      startDateBox: {
        value: null,
        type: 'time',
        pickerType: 'list',
        interval: 30,
        displayFormat: 'HH:mm',
        min: new Date(),
        dateOutOfRangeMessage: '종료시간은 시작시간보다 커야 합니다.',
        disabled: false,
        stylingMode: 'outlined',
        width: 110,
        placeholder: 'HH:mm'
      },
      endDateBox: {
        value: null,
        type: 'time',
        pickerType: 'list',
        interval: 30,
        displayFormat: 'HH:mm',
        min: new Date(),
        dateOutOfRangeMessage: '종료시간은 시작시간보다 커야 합니다.',
        disabled: false,
        stylingMode: 'outlined',
        width: 110,
        placeholder: 'HH:mm'
      },
      attendanceReason: {
        contents: '',
        stylingMode: 'outlined',
        width: 500,
        height: 100,
        placeholder: '변경 사유를 입력하세요.',
      },
    };
  },
  watch: {
    isOpen() {
      // 팝업이 열릴 때 초기화
      if (this.isOpen) {
        this.changeAttendanceState = '';
        this.startDateBox.value = null;
        this.endDateBox.value = null;
        this.attendanceReason.contents = '';
      }
    },
  },
  computed: {},
  methods: {
    saveModal() {
      const instance = this.$refs.attendanceModifyValidate.instance;
      if (instance.validate().isValid) {
        // TODO : 출결정보저장 API 호출, API쪽 정리가 필요하여 추후에 추가
        this.$_Msg('연결된 API가 없습니다.');
      } else {
        this.$_Toast(this.$_msgContents(
          'COMMON.MESSAGE.REQUIRED_VALUE_VALIDATION_ERROR',
          {defaultValue: '필수값을 입력해주세요.'})
        );
      }
    },
    closeModal() {
      this.$emit('closeModal');
    },
    /**
     * 출결상태 코드값을 받아서 출결상태명으로 변환
     * @return {string}
     */
    getAttendanceState() {
      if (this.attendanceState === 'tardiness') {
        return '지각';
      }

      if (this.attendanceState === 'absence') {
        return '결근';
      }

      if (this.attendanceState === 'earlyLeave') {
        return '조퇴';
      }
    },
    /**
     * 출결상태 코드값 리스트
     * @return {Array}
     */
    getAttendanceStateList() {
      return [
        {id: 'normal', codeNm: '정상'},
        {id: 'tardiness', codeNm: '지각'},
        {id: 'absence', codeNm: '결근'},
        {id: 'dayoff', codeNm: '조퇴'},
      ];
    },
  },
  created() {
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped></style>